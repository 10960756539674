import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  fetchDrugsByKeyword,
  getAllDrugs,
  getBundles,
  createBundle,
  editBundle,
  deleteMedication
} from 'requests/inventory';
import { debounce } from 'lodash';

import {
  getAllOrders,
  getAllSubscription,
  pickupOrder,
  logOrder,
  updateOrderStatePharmDispatch,
  refundAmount
} from 'requests/order';

import { userIsAuthenticated } from 'utils/auth-util';
import {
  customerCouponValidate,
  deleteCoupon,
  generateCouponCode,
  getAllCoupons,
  postCoupon,
  editCoupon,
  updateCouponStatus,
  couponUsageHistory
} from 'requests/coupon';
import toast from 'react-hot-toast';
import {
  addCommentToConsultation,
  assignRunnersToConsultation,
  deleteConsultation,
  getAllConsultations,
  getConsultationById,
  getConsultationStats,
  signalADoc,
  updateConsultation
} from 'requests/consultations';
import { useStore } from 'store';
import { Routes } from 'constant';
import { getAllUsers, getUsersSummary, getUserOrders } from 'requests/user';
import {
  createRole,
  createRunner,
  getAllRoles,
  getAllRunners,
  getAppFeatures,
  getRole,
  updateRole,
  updateRunner,
  updateUserActivationStatus
} from 'requests/runner';
import moment from 'moment';
import { getActivity } from 'requests/activity';
import {
  activateCampaign,
  createCampaign,
  deActivateCampaign,
  deleteCampaign,
  getAllCampaigns,
  getCampaignById,
  updateCampaign
} from 'requests/campaign';
import { getWellnessCheckerHistory, getWellnessCheckerStats } from 'requests/wellness';
import { useNavigate } from 'react-router-dom';
import { getPharmOrderRequests, getPharmStats } from 'requests/pharmacy';
import Permission from 'utils/permissions';
import { getTransactionHistory, getTransactionHistoryByOrderNumber, getTransactionSummary } from 'requests/transactions';

interface FnTypes {
  [key: string]: (open: boolean) => void;
}

const roleChecker = (store: any, role: string | string[]): boolean => {
  const permission = new Permission(store);

  return permission.hasFeatureAccess(role);
};

export const useGetDrugs = (value: string, fn?: FnTypes) => {
  const debouncedFetchDrugs = useCallback(debounce(fetchDrugsByKeyword, 300), [value]);

  return useQuery({
    queryKey: ['search-drugs', value],
    queryFn: async () => {
      const val = value.trim();
      if (val.length > 2) {
        const { data }: any = await debouncedFetchDrugs(val);
        const resp = data.map((drug: IDrug) => ({
          label: drug.brandName,
          value: drug._id,
          drugData: drug
        }));
        if (fn) {
          const { toggleMedicationItems, updateTooltipMessage } = fn;
          updateTooltipMessage(data.length < 1);
          toggleMedicationItems(resp.length > 0);
        }
        return resp;
      }
    }
  });
};

export const useInventory = (
  pageNumber: number,
  size: number,
  queryString: string,
  setInventory: any
) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['all-drugs', pageNumber, size, queryString],
    queryFn: async () => {
      const { data }: any = await getAllDrugs(pageNumber, size, queryString);
      setInventory(data);
      return data;
    },
    staleTime: 6000000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewProducts')
  });
};

export const useBundles = (
  pageNumber: number,
  pageSize: number,
  queryString: string,
  setBundle?: any
) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['all-bundles', pageNumber, pageSize, queryString],
    queryFn: async () => {
      const { data }: any = await getBundles(pageNumber, pageSize, queryString);
      setBundle(data);
      return data;
    },
    refetchOnWindowFocus: true,
    staleTime: 6000000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewProducts')
  });
};

export const useCreateBundle = (toggleCreateBundleItemModal: () => void) => {
  const {
    InventoryStore: { currentPage, pageLimit, queryString }
  } = useStore();
  const { refetch } = useBundles(currentPage, pageLimit, queryString);
  return useMutation({
    mutationFn: async (payload: IBundle) => {
      return createBundle(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        refetch();
        toggleCreateBundleItemModal();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useUpdateBundle = (toggleCreateBundleItemModal: () => void) => {
  const {
    InventoryStore: { currentPage, pageLimit, queryString }
  } = useStore();
  const { refetch } = useBundles(currentPage, pageLimit, queryString);
  return useMutation({
    mutationFn: async (payload: IBundle) => {
      return editBundle(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateBundleItemModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useAdminRecentOrders = (
  pageNumber: number,
  size: number,
  query: string,
  setOrder: any
) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['recent-orders-stat', pageNumber, size, query],
    queryFn: async () => {
      const { data }: any = await getAllOrders(pageNumber, size, query);
      setOrder(data);
      return data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewOrders')
  });
};

export const useSubscriptionOrder = (params: IPaginatedParams) => {
  const { AuthStore } = useStore();
  const { page, limit, searchKey, startDate, endDate } = params;

  return useQuery({
    queryKey: ['all-subscription', page, limit, searchKey, startDate, endDate],
    queryFn: async () => {
      const payload = {
        ...params,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
      };
      const response = await getAllSubscription(payload);
      return response?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewOrders')
  });
};

export const useAllCustomers = (params: GetAllUsersTypes) => {
  const {
    AuthStore,
    UserStore: { filterOptions }
  } = useStore();
  const { page, pageSize, searchKey, startDate, endDate } = params;

  return useQuery({
    queryKey: ['all-customers', page, pageSize, searchKey, startDate, endDate],
    queryFn: async () => {
      const payload = {
        ...params,
        customerStatus: filterOptions['customerStatus'].join(','),
        orderStatus: filterOptions['orderStatus'].length > 1 ? '' : filterOptions['orderStatus'][0],
        customerCategory:
          filterOptions['customerCategory'].length > 1 ? '' : filterOptions['customerCategory'][0],
        activeStatus:
          filterOptions['activeStatus'].length > 1 ? '' : filterOptions['activeStatus'][0],
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
      };
      const response = await getAllUsers(payload);
      return response?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewCustomers')
  });
};

export const useUserOrders = (userId: string, page: number, limit: number, query?: any) => {
  const { location, orderStatus, searchKey, startDate, endDate, isLogged, orderStage } = query;
  const {
    PartnerStore: { filterPartnerOrderOptions }
  } = useStore();

  return useQuery({
    queryKey: [
      'user-orders',
      location,
      isLogged,
      orderStatus,
      searchKey,
      startDate,
      endDate,
      orderStage
    ],
    queryFn: async () => {
      const formattedParams = {
        ...query,
        location: filterPartnerOrderOptions['location']?.join(','),
        isLogged:
          filterPartnerOrderOptions['isLogged'].length > 1
            ? ''
            : filterPartnerOrderOptions['isLogged'][0],
        orderStatus: filterPartnerOrderOptions['orderStatus']?.join(','),
        startDate: startDate ? moment(startDate)?.format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate)?.format('YYYY-MM-DD') : '',
        orderStage: filterPartnerOrderOptions['orderStage'].join(',')
      };
      const queryString = new URLSearchParams(formattedParams).toString();

      const response = await getUserOrders(userId, page, limit, queryString);
      return response?.data;
    },
    staleTime: 300000,
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated()
  });
};
export const useTransactionHistory = (
  userId: string,
  admin = 'customer',
  page: number,
  size: number,
  type?: string,
  startDate?: string,
  endDate?: string,
  transactionType?: string,
  searchKey?: string,
  balanceStatus?: string
) => {
  const {
    FinanceStore: { filterTransactionOptions }
  } = useStore();

  return useQuery({
    queryKey: ['user-transaction-history', userId, page, size, type, transactionType, searchKey, startDate, endDate, balanceStatus],
    queryFn: async () => {



      const transactionTypeValue = filterTransactionOptions['transactionType'].length > 1 ? '' : filterTransactionOptions['transactionType'][0] || transactionType
      const balanceStatus = filterTransactionOptions['balanceStatus'].length > 1 ? '' : filterTransactionOptions['balanceStatus'][0]

      const startDateValue = startDate ? moment(startDate)?.format('YYYY-MM-DD') : '';
      const endDateValue = endDate ? moment(endDate)?.format('YYYY-MM-DD') : '';

      const response = await getTransactionHistory(userId,
        admin,
        page,
        size,
        type,
        startDateValue,
        endDateValue,
        transactionTypeValue,
        balanceStatus,
        searchKey,
      );
      return response?.data;
    },
    staleTime: 300000,
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated()
  });
};

export const useActivities = (
  params: IPaginatedRequest & { entityId: string } & { service?: string }
) => {
  const { page, pageSize, entityId, service } = params;

  return useQuery({
    queryKey: ['all-activities', entityId, page, pageSize, service],
    queryFn: async () => {
      const response = await getActivity(params);
      return response?.data;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated()
  });
};

export const usePharmOrderRequests = (params: GetAllUsersTypes & { pharmacyId: string }) => {
  const { pharmacyId, page, pageSize, startDate, endDate, searchKey } = params;
  return useQuery({
    queryKey: ['pharm-order-requests', pharmacyId, page, pageSize, startDate, endDate, searchKey],
    queryFn: async () => {
      const payload = {
        ...params,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
      };
      const response = await getPharmOrderRequests(payload);
      return response?.data;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated()
  });
};

export const usePharmStats = (params: GetAllUsersTypes & { pharmacyId: string }) => {
  const { pharmacyId, startDate, endDate } = params;
  return useQuery({
    queryKey: ['pharm-stats', pharmacyId, startDate, endDate],
    queryFn: async () => {
      const payload = {
        ...params,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
      };
      const response = await getPharmStats(payload);
      return response?.data;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated()
  });
};

export const useTransactionHistoryByOrderNumber = (userId: string, orderNumber: string) => {

  return useQuery({
    queryFn: async () => {
      const res = await getTransactionHistoryByOrderNumber(userId,
        orderNumber);
      return res?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated()
  });
};
export const useWalletTransactionSummary = (params: GetAllUsersTypes) => {
  const { startDate, endDate, searchKey, userId } = params;

  return useQuery({
    queryKey: ['wallet-transaction-summary', userId, startDate, endDate, searchKey],
    queryFn: async () => {
      const payload = {
        ...params,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',

      };
      const res = await getTransactionSummary(payload as any);
      return res?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated()
  });
};

export const useUsersSummary = (params: GetAllUsersTypes) => {
  const {
    AuthStore,
    UserStore: { filterOptions }
  } = useStore();
  const { startDate, endDate, searchKey } = params;

  return useQuery({
    queryKey: ['users-summary', startDate, endDate, searchKey, filterOptions],
    queryFn: async () => {
      const payload = {
        ...params,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
        customerStatus: filterOptions['customerStatus'].join(','),
        orderStatus: filterOptions['orderStatus'].length > 1 ? '' : filterOptions['orderStatus'][0],
        activeStatus:
          filterOptions['activeStatus'].length > 1 ? '' : filterOptions['activeStatus'][0]
      };
      const res = await getUsersSummary(payload);
      return res?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewCustomers')
  });
};

export const useRunners = (params: IParams) => {
  const { AuthStore } = useStore();

  const { searchKey, role, pageNumber, pageSize } = params;
  return useQuery({
    queryKey: ['all-runners', searchKey, role, pageNumber, pageSize],
    queryFn: async () => {
      try {
        const response = await getAllRunners(params);
        return response?.data;
      } catch (error) {
        console.error('Error fetching runners:', error);
        throw error;
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewRunners')
  });
};

export const useRoles = () => {
  const { AuthStore } = useStore();
  return useQuery({
    queryKey: ['all-roles'],
    queryFn: async () => {
      try {
        const response = await getAllRoles();
        return response?.data as IRole[];
      } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewRoles')
  });
};

export const useRole = (id: string) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['single-role', id],
    queryFn: async () => {
      try {
        const response = await getRole(id);
        return response?.data;
      } catch (error) {
        console.error('Error fetching role:', error);
        throw error;
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewRoles')
  });
};

export const useAppFeatures = () => {
  return useQuery({
    queryKey: ['app-features'],
    queryFn: async () => {
      try {
        const response = await getAppFeatures();
        return response?.data;
      } catch (error) {
        console.error('Error fetching role:', error);
        throw error;
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated()
  });
};

export const useAllConsultations = (params: GetAllUsersTypes) => {
  const {
    AuthStore,
    ConsultationStore: { startDate, endDate, filterOptions }
  } = useStore();
  const { page, pageSize, searchKey } = params;

  return useQuery({
    queryKey: ['all-consultations', page, pageSize, startDate, endDate, searchKey],
    queryFn: async () => {
      const payload = {
        ...params,
        status: filterOptions['status'].join(','),
        customerSource:
          filterOptions['customerSource'].length > 1 ? '' : filterOptions['customerSource'][0],
        type: filterOptions['type'].length > 1 ? '' : filterOptions['type'][0]?.toUpperCase(),
        partnerType: filterOptions['partnerType'].length > 1 ? '' : filterOptions['partnerType'][0],
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : ''
      };
      const response = await getAllConsultations(payload);
      return response?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewConsultations')
  });
};

export const useConsultationDetails = (id: string) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['single-consultation', id],
    queryFn: async () => {
      if (id) {
        const response = await getConsultationById(id);
        return response?.data;
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewConsultation')
  });
};

export const useUpdateConsultation = (id: string, setFns: Record<string, any>) => {
  const { setAssessment } = setFns;
  const { refetch } = useConsultationDetails(id ?? '');
  return useMutation({
    mutationFn: async (payload: Record<string, any>) => {
      if (id) {
        return updateConsultation(id, payload);
      }
    },
    onSettled: (res: any) => {
      if (res && res.status) {
        setAssessment('');
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useDeleteConsultation = (id: string) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async () => {
      if (id) {
        return deleteConsultation(id);
      }
    },
    onSettled: (res: any) => {
      if (res && res.status) {
        toast.success(res.message);
        navigate(Routes.AdminOrdersList);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useAssignRunnersToConsultations = (id: string) => {
  const { refetch } = useConsultationDetails(id ?? '');
  return useMutation({
    mutationFn: async (payload: { _id: string; firstName: string }) => {
      if (id) {
        return assignRunnersToConsultation(id, {
          assigneeId: payload._id,
          assigneeName: payload.firstName
        });
      }
    },
    onSettled: (res: any) => {
      if (res && res.status) {
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useAddCommentToConsultation = () => {
  const {
    ConsultationStore: { toggleConsultationAddCommentModal, consultationId }
  } = useStore();

  const { refetch } = useConsultationDetails(consultationId ?? '');
  return useMutation({
    mutationFn: async (message: string) => {
      if (consultationId) {
        return addCommentToConsultation(consultationId, message);
      }
    },
    onSettled: (res: any) => {
      if (res && res.status) {
        toggleConsultationAddCommentModal();
        refetch();
        toast.success(res.message);
        return res;
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useWellnessStats = () => {
  const {
    AuthStore,
    ConsultationStore: { startDate, endDate }
  } = useStore();

  return useQuery({
    queryKey: ['wellness-history-stats', startDate, endDate],
    queryFn: async () => {
      const response = await getWellnessCheckerStats({ startDate, endDate });
      return response?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewHealthCondition')
  });
};

export const useWellnessHistory = (type: string) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['wellness-history', type],
    queryFn: async () => {
      const response = await getWellnessCheckerHistory(type);
      return response?.data;
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewHealthCondition')
  });
};

export const useCreateRunner = (toggleCreateRunnerModal: () => void) => {
  const {
    RunnerStore: { params, currentPage, pageLimit }
  } = useStore();
  const refetchData = { ...params, pageNumber: currentPage, pageSize: pageLimit };
  const { refetch } = useRunners(refetchData);
  return useMutation({
    mutationFn: async (payload: RunnerInterface) => {
      return createRunner(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateRunnerModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useUpdateRunner = (toggleCreateRunnerModal: () => void) => {
  const {
    RunnerStore: { params, currentPage, pageLimit }
  } = useStore();
  const payload = { ...params, pageNumber: currentPage, pageSize: pageLimit };

  const { refetch } = useRunners(payload);
  return useMutation({
    mutationFn: async (payload: RunnerInterface) => {
      return updateRunner(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateRunnerModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useCreateRole = () => {
  const navigate = useNavigate();
  const { refetch } = useRoles();
  return useMutation({
    mutationFn: async (payload: IRole) => {
      return createRole(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        refetch();
        toast.success(res.message);
        navigate(Routes.AdminRoles);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useUpdateRole = () => {
  const { refetch } = useRoles();
  return useMutation({
    mutationFn: async (payload: IRole & { roleId: string }) => {
      const { roleId, ...rest } = payload;

      return updateRole(rest, roleId);
    },
    onSettled: (res) => {
      if (res && res.status) {
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useStatusRunnerAction = (toggleStatusRunnerModal: () => void) => {
  const {
    RunnerStore: { params, currentPage, pageLimit }
  } = useStore();
  const payload = { ...params, pageNumber: currentPage, pageSize: pageLimit };

  const { refetch } = useRunners(payload);
  return useMutation({
    mutationFn: async (args: { userId: string; status: boolean; statusReason: string }) => {
      const { userId, status, statusReason } = args;
      return updateUserActivationStatus(userId, status, statusReason);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleStatusRunnerModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useGenerateCouponCode = () => {
  return useQuery({
    queryKey: ['generate-coupon-code'],
    queryFn: async () => {
      const res = await generateCouponCode();
      return res?.data?.couponCode;
    },
    enabled: false
  });
};

export const useCoupon = (params: GetAllCouponsParams) => {
  const { AuthStore } = useStore();
  const { page: pageNumber, pageSize: size, status, couponType, searchKey } = params;

  return useQuery({
    queryKey: ['all-coupons', pageNumber, size, status, couponType, searchKey],
    queryFn: async () => {
      const response = await getAllCoupons(params);
      if (response?.status) {
        return response.data as IPaginatedCoupon;
      } else {
        const message = response?.message ?? 'Something went wrong';
        toast.error(message, {
          position: 'top-right'
        });
      }
    },
    staleTime: 300000,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewCoupons')
  });
};

export const useCreateCoupon = (toggleCreateCouponModal: () => void) => {
  const {
    CouponStore: { params }
  } = useStore();
  const { refetch } = useCoupon(params);
  return useMutation({
    mutationFn: async (payload: ICoupon) => {
      return postCoupon(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateCouponModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};
export const useEditCoupon = (toggleCreateCouponModal: () => void) => {
  const {
    CouponStore: { params, initialValues }
  } = useStore();
  const { refetch } = useCoupon(params);
  return useMutation({
    mutationFn: async (payload: ICoupon) => {
      return editCoupon(payload, initialValues?.couponCode ?? '');
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateCouponModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};
export const useCouponAction = (toggleCouponActionModal: (type: string) => void, type: string) => {
  const {
    CouponStore: { params }
  } = useStore();
  const { refetch } = useCoupon(params);
  return useMutation({
    mutationFn: async (couponCode: string) => {
      return type === 'update' ? updateCouponStatus(couponCode) : deleteCoupon(couponCode);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCouponActionModal(type);
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const useValidateCoupon = (couponCode: string) => {
  const {
    CheckoutStore: { setValidatedCoupon, deliveryAddress },
    AuthStore: { user }
  } = useStore();
  return useQuery({
    queryKey: ['validate-coupon'],
    queryFn: async () => {
      const email = userIsAuthenticated() ? user?.email : deliveryAddress?.email;
      const res = await customerCouponValidate(couponCode, email ?? '');
      if (res?.status) {
        toast.success('Coupon Applied Successfully', {
          position: 'top-right'
        });
        setValidatedCoupon(res.data);
        return res?.data;
      }
      setValidatedCoupon(null);
      toast.error(res?.message ?? 'Something went wrong', {
        position: 'top-right'
      });
    },
    enabled: false
  });
};

export const useGetCouponHistory = (couponCode: string) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['get-coupon-history', couponCode],
    queryFn: async () => {
      if (couponCode) {
        const res = await couponUsageHistory(couponCode);
        return res?.data;
      }
    },

    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'TrackCouponUsage')
  });
};

export const useSignalDoc = () => {
  return useMutation({
    mutationFn: () => {
      return signalADoc();
    },
    onSettled: (res) => {
      if (res && res.status) {
        toast.success('Redirecting to WhatsApp');
        setTimeout(() => {
          if (res) {
            const { data } = res;
            window.open(data?.redirectUrl);
          }
        }, 2000);
      } else {
        toast.error(res?.message ?? 'Something went wrong, try again later');
      }
    }
  });
};

export const useCampaign = (pageNumber: number, pageSize: number) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['all-campaign', pageNumber, pageSize],
    queryFn: async () => {
      const response = await getAllCampaigns(pageNumber, pageSize);
      return response?.data as IPaginatedCampaign | any;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewCampaign')
  });
};

export const useCampaignId = (campaignId: string) => {
  const { AuthStore } = useStore();

  return useQuery({
    queryKey: ['all-campaignId', campaignId],
    queryFn: async () => {
      const response = await getCampaignById(campaignId);
      return response?.data;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewCampaign')
  });
};

export const useCreateCampaign = (toggleCreateCampaignModal: () => void) => {
  const {
    CampaignStore: { pageNumber, pageSize }
  } = useStore();
  const { refetch } = useCampaign(pageNumber, pageSize);
  return useMutation({
    mutationFn: async (payload: ICampaign) => {
      return createCampaign(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateCampaignModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};
export const useUpdateCampaign = (toggleCreateCampaignModal: () => void, campaignId: string) => {
  const {
    CampaignStore: { pageNumber, pageSize }
  } = useStore();
  const { refetch } = useCampaign(pageNumber, pageSize);
  return useMutation({
    mutationFn: async (payload: ICampaign) => {
      return updateCampaign(payload, campaignId);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleCreateCampaignModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};
export const useCampaignStatusAction = (
  toggleStatusCampaignModal: () => void,
  campaignId: string,
  isActive: boolean,
  deactivationReason?: string
) => {
  const {
    CampaignStore: { pageNumber, pageSize }
  } = useStore();
  const { refetch } = useCampaign(pageNumber, pageSize);
  return useMutation({
    mutationFn: async () => {
      const statusAction = isActive
        ? deActivateCampaign(campaignId, deactivationReason)
        : activateCampaign(campaignId);

      return statusAction;
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleStatusCampaignModal();
        refetch();
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};
export const useCampaignDeleteAction = (
  campaignId: string,
  deletionReason: string,
  toggleDeleteCampaignModal: () => void
) => {
  const {
    CampaignStore: { pageNumber, pageSize }
  } = useStore();
  const { refetch } = useCampaign(pageNumber, pageSize);

  return useMutation({
    mutationFn: async () => {
      return deleteCampaign(campaignId, deletionReason);
    },
    onSettled: (res, error) => {
      if (error) {
        // Handle error case
        toast.error(res?.message ?? 'Something went wrong');
      } else if (res && res.status) {
        // Handle success case
        toggleDeleteCampaignModal();
        refetch();
        toast.success(res.message);
      } else {
        // Handle unexpected result
        toast.error('Unexpected result. Something went wrong.');
      }
    }
  });
};

export const usePostRefund = (data: IOrder) => {
  const {
    AdminOrderStore: { toggleRefundFormModal, fetchOrderByOrderNumber, refundType }
  } = useStore();

  return useMutation({
    mutationFn: async (refundDetails: IRefundReason) => {
      const payload = {
        channel: refundType,
        orderData: data,
        refundDetails
      };
      return refundAmount(payload);
    },
    onSettled: (res) => {
      if (res && res.status) {
        toggleRefundFormModal();
        fetchOrderByOrderNumber(data?.orderNumber);
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const usePostPickupOrder = (orderNumber: string) => {
  const {
    AdminOrderStore: { fetchOrderByOrderNumber }
  } = useStore();

  return useMutation({
    mutationFn: async () => {
      return pickupOrder(orderNumber);
    },
    onSettled: (res) => {
      if (res && res.status) {
        fetchOrderByOrderNumber(orderNumber);
        toast.success(res.message);
      } else {
        toast.error(res?.message ?? 'Something went wrong');
      }
    }
  });
};

export const usePostLogOrder = (orderNumber: string) => {
  const {
    AdminOrderStore: { fetchOrderByOrderNumber }
  } = useStore();
  return useMutation({
    mutationFn: async () => {
      return logOrder(orderNumber);
    },
    onSettled: (res, err) => {
      if (res && res.status) {
        fetchOrderByOrderNumber(orderNumber);
        toast.success(res.message);
      } else {
        console.log(err);
      }
    }
  });
};

export const useUpdateOrderStatePharmDispatch = (orderNumber: string) => {
  const {
    AdminOrderStore: { fetchOrderByOrderNumber }
  } = useStore();
  return useMutation({
    mutationFn: async (params: IOrderState) => {
      const { orderNumber, type, body } = params;
      return updateOrderStatePharmDispatch(orderNumber, type, body);
    },
    onSettled: (res, err) => {
      if (res && res.status) {
        fetchOrderByOrderNumber(orderNumber);
        toast.success(res.message);
      } else {
        console.log(err);
      }
    }
  });
};

export const useGetConsultationStats = () => {
  const {
    AuthStore,
    ConsultationStore: { startDate, endDate }
  } = useStore();

  return useQuery({
    queryKey: ['consultation-stats', startDate, endDate],
    queryFn: async () => {
      const response = await getConsultationStats({ startDate, endDate });
      return response?.data;
    },
    refetchOnWindowFocus: true,
    enabled: userIsAuthenticated() && roleChecker(AuthStore, 'ViewConsultations')
  });
};

export const useDeleteMedication = () => {
  const {
    InventoryStore: { queryString, currentPage, pageLimit, setInventory }
  } = useStore();

  const { refetch } = useInventory(currentPage, pageLimit, queryString, setInventory);

  return useMutation({
    mutationFn: async (drugId: string) => {
      return deleteMedication(drugId);
    },
    onSettled: (res, err) => {
      if (res && res.status) {
        refetch();
        toast.success(res.message);
      } else {
        console.log(err);
      }
    }
  });
};
