import {
  FormControl,
  Input,
  Flex,
  Select,
  Button,
  Card,
  Heading,
  Divider,
  VStack,
  FormErrorMessage,
  Box,
  Text,
  Icon
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { states } from 'data/location';
import { useStore } from 'store';
import { Form, Formik } from 'formik';
import { ReceiverDetailsSchema } from 'validations/authValidators';
import { toast } from 'react-hot-toast';
import GooglePlacesInput from 'components/placeinput';
import { useParams } from 'react-router-dom';
import { InfoIcon, SmallCloseIcon } from '@chakra-ui/icons';
import CustomToolTip from 'components/customToolTip';

const PartnerReceipientFormDetails = () => {
  const { id } = useParams();
  const {
    CheckoutStore: {
      isDeliveryAddressCardOpen,
      toggleGuestAddressForm,
      selectAddress,
      deliveryAddress,
      addressToEditIndex,
      setAddressToEditIndex,
      newOrderAddressForm,
      toggleNewOrderAddressForm
    },
    UserStore: { newFamAndFriend, selectUserProfile, updateUserAddressAdmin },
    PartnerStore: {
      addPartnerMemberAdmin,
      partnerMembersAdmin,
      isMemberState: { isMember },
      updatePartnerMemberAdmin
    }
  } = useStore();
  const handleDiscardChanges = () => {
    setAddressToEditIndex('');
    selectAddress('default', false);
    if (!isMember) toggleGuestAddressForm();
    if (newFamAndFriend === 'new') {
      toggleNewOrderAddressForm();
    }
  };

  const ToolTipInfo = () => {
    return (
      <Box bg={`white`} color={`black`} borderRadius={`8px`}>
        <Text
          fontSize={{
            base: '11.5px',
            md: '15px'
          }}
          fontWeight={`400`}
        >
          {' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            unique identifier that you use to identify your customer
          </span>
        </Text>
      </Box>
    );
  };
  const treatAsPartner = addressToEditIndex === 'default' && selectUserProfile?.userId === id;

  let initialValues = deliveryAddress;
  if (
    newOrderAddressForm ||
    (addressToEditIndex === 'default' && deliveryAddress.email === '') ||
    addressToEditIndex === 'guest'
  ) {
    initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      businessId: '',
      state: '',
      phoneNumber: '',
      street: '',
      longitude: '',
      latitude: '',
      landmark: ''
    };
  }
  const handleSubmit = async (values: IAddress) => {
    if (treatAsPartner) {
      const payload = {
        street: values.street,
        city: values.city,
        state: values.state,
        longitude: values.longitude,
        latitude: values.latitude,
        landmark: values.landmark
      };

      await updateUserAddressAdmin(payload, 'Business', id as string);
      selectAddress('default', false);
      setAddressToEditIndex('');
      return;
    } else {
      const memberAdddress = {
        street: values.street,
        city: values.city,
        state: values.state,
        longitude: values.longitude,
        latitude: values.latitude,
        businessId: values.businessId,
        landmark: values.landmark,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber
      };

      if (newFamAndFriend === 'new') {
        const res = addPartnerMemberAdmin(
          memberAdddress as IMember,
          selectUserProfile?.userId as string
        );
        if (res) {
          toast.success('Member details added successfully', {
            position: 'top-right'
          });
        }
        toggleNewOrderAddressForm();
        selectAddress(`${partnerMembersAdmin.length - 1}`, true);
      } else {
        const membersIndex = parseInt(addressToEditIndex);
        const memberToEditId = partnerMembersAdmin[membersIndex]?._id;

        const res = updatePartnerMemberAdmin(
          memberAdddress as IMember,
          memberToEditId as string,
          id as string
        );
        if (res) {
          toast.success('Member details updated successfully', {
            position: 'top-right'
          });
        }
        selectAddress(`${addressToEditIndex}`, true);
      }
    }
  };

  return (
    <>
      {!isDeliveryAddressCardOpen && (
        <Card p="2rem">
          <Flex justifyContent="space-between" gap="1rem">
            <Heading fontSize="15px" mb="1rem">
              {treatAsPartner ? `PARTNER'S` : `MEMBER'S`} DETAILS
            </Heading>
            <Icon
              onClick={handleDiscardChanges}
              fontSize="20px"
              mt=".2rem"
              color="#EA1831"
              borderRadius="50%"
              bg="#FEF2F2"
            >
              <SmallCloseIcon />
            </Icon>
          </Flex>
          <Divider color="gray" mb="3" />
          <Formik
            initialValues={initialValues}
            validationSchema={ReceiverDetailsSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem'
                }}
              >
                <>
                  {treatAsPartner && (
                    <>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="business name">
                        Business Name
                      </FormControl>
                      <Input
                        id="businessName"
                        value={selectUserProfile?.business?.businessName as string}
                        name="businessName"
                        onChange={handleChange}
                        backgroundColor={'#F5F5F5'}
                        type="text"
                        fontSize={{ base: '1rem', md: '0.875rem' }}
                        disabled={treatAsPartner}
                        _placeholder={{
                          fontSize: { base: '12px', md: '14px' }
                        }}
                        aria-label="business name"
                      />
                    </>
                  )}
                  {!treatAsPartner && (
                    <>
                      <FormControl isInvalid={errors.firstName && touched.firstName ? true : false}>
                        <FormControl fontSize="14px" fontWeight="500" aria-label="first name">
                          First name
                        </FormControl>
                        <Input
                          id="firstName"
                          value={values.firstName}
                          name="firstName"
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter first name"
                          disabled={treatAsPartner}
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="first name"
                        />
                        <FormErrorMessage>
                          {errors.firstName && touched.firstName ? errors.firstName : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={errors.lastName && touched.lastName ? true : false}>
                        <FormControl fontSize="14px" fontWeight="500" aria-label="lastName">
                          Last name
                        </FormControl>
                        <Input
                          id="lastName"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter last name"
                          disabled={treatAsPartner}
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="last name"
                        />
                        <FormErrorMessage>
                          {errors.lastName && touched.lastName ? errors.lastName : null}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors.businessId && touched.businessId ? true : false}
                      >
                        <FormControl fontSize="14px" fontWeight="500" aria-label="businessId">
                          Member ID{' '}
                          <CustomToolTip
                            label={<ToolTipInfo />}
                            placement="right-start"
                            maxW="800px"
                            bg="white"
                          >
                            <InfoIcon color="primary.main" mb="1" />
                          </CustomToolTip>
                        </FormControl>
                        <Input
                          id="businessId"
                          name="businessId"
                          value={values.businessId}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter MEMBER ID"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="member id"
                        />
                        <FormErrorMessage>
                          {errors.businessId && touched.businessId ? errors.businessId : null}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                  <FormControl isInvalid={errors.email && touched.email ? true : false}>
                    <FormControl fontSize="14px" fontWeight="500" aria-label="email">
                      Email Address
                    </FormControl>
                    <Input
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      backgroundColor={'#F5F5F5'}
                      type="email"
                      placeholder="Email"
                      disabled={treatAsPartner}
                      _placeholder={{
                        fontSize: { base: '12px', md: '14px' }
                      }}
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      aria-label="email"
                    />
                    <FormErrorMessage>
                      {errors.email && touched.email ? errors.email : null}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.phoneNumber && touched.phoneNumber ? true : false}>
                    <FormControl fontSize="14px" fontWeight="500" aria-label="phone number">
                      Phone number
                    </FormControl>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      backgroundColor={'#F5F5F5'}
                      type="tel"
                      fontSize={{ base: '1rem', md: '0.875rem' }}
                      placeholder="Phone number"
                      disabled={treatAsPartner}
                      _placeholder={{
                        fontSize: { base: '12px', md: '14px' }
                      }}
                      aria-label="phone number"
                    />
                    <FormErrorMessage>
                      {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                    </FormErrorMessage>
                  </FormControl>
                </>

                <GooglePlacesInput
                  defaultValue={values.street}
                  error={(errors.street && touched.street) || false}
                  handleChange={handleChange}
                  errorMsg={errors.street}
                  inputProps={{
                    _placeholder: {
                      fontSize: { base: '12px', md: '14px' }
                    }
                  }}
                />

                <Flex gap="2rem">
                  <VStack align="start" w="100%">
                    <FormControl isInvalid={errors.state && touched.state ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="state">
                        State
                      </FormControl>
                      <Select
                        id="state"
                        placeholder={'All locations'}
                        name="state"
                        value={values.state}
                        fontSize={{ base: '12px', md: '14px' }}
                        onChange={handleChange}
                        backgroundColor={'#F5F5F5'}
                        aria-label="state"
                      >
                        {states.map((state: { name: string }) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.state && touched.state ? errors.state : null}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                  <VStack align="start" w="100%">
                    <FormControl isInvalid={errors.city && touched.city ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="city">
                        City
                      </FormControl>
                      {values.state !== 'Lagos' ? (
                        <Input
                          id="city"
                          name="city"
                          value={values.city}
                          disabled={!values.state}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter city"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="city"
                        />
                      ) : (
                        <Select
                          id="city"
                          placeholder={'Select city'}
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          backgroundColor={'#F5F5F5'}
                          fontSize={{ base: '12px', md: '14px' }}
                          aria-label="city"
                        >
                          {states
                            .find((state: { name: string }) => state.name === values.state)
                            .cities.map(
                              (
                                city: {
                                  name: string;
                                },
                                index: number
                              ) => (
                                <option key={index} value={city.name}>
                                  {city.name}
                                </option>
                              )
                            )}
                        </Select>
                      )}
                      <FormErrorMessage>
                        {errors.city && touched.city ? errors.city : null}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Flex>

                <FormControl isInvalid={errors.landmark && touched.landmark ? true : false}>
                  <FormControl fontSize="14px" fontWeight="500" aria-label="landmark">
                    Landmark
                  </FormControl>
                  <Input
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onChange={handleChange}
                    backgroundColor={'#F5F5F5'}
                    type="text"
                    fontSize={{ base: '1rem', md: '0.875rem' }}
                    placeholder="Enter Landmark"
                    _placeholder={{
                      fontSize: { base: '12px', md: '14px' }
                    }}
                    aria-label="landmark"
                  />
                  <FormErrorMessage>
                    {errors.landmark && touched.landmark ? errors.landmark : null}
                  </FormErrorMessage>
                </FormControl>

                <Flex justify="end" gap="1rem">
                  <Button
                    aria-label="discard changes"
                    variant="outline"
                    color="primary.main"
                    borderColor="primary.main"
                    onClick={handleDiscardChanges}
                    type="button"
                    size="sm"
                    display={
                      deliveryAddress === null ||
                      values.firstName === '' ||
                      values.lastName === '' ||
                      values.email === '' ||
                      values.phoneNumber === '' ||
                      values.street === '' ||
                      values.state === '' ||
                      values.city === '' ||
                      values.landmark === ''
                        ? 'none'
                        : 'block'
                    }
                  >
                    Discard Changes
                  </Button>
                  <Button type="submit" size="sm" aria-label="save changes">
                    {' '}
                    Save Changes
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default observer(PartnerReceipientFormDetails);
