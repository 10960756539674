import toast from 'react-hot-toast';
import { client } from '..';
import config from '../../config';

export const getAllOrders = async (
  pageNumber: number,
  size: number,
  query?: string
): Promise<ApiResponse<IOrder[]> | undefined> => {
  let url = `${config.AUTH_BASE_URL}/api/order/users/all/${pageNumber}/${size}`;

  if (query) url += `${query}`;
  return await client<ApiResponse<IOrder[]>>(url, 'GET');
};

export const getUserOrders = async (): Promise<ApiResponse<IOrder[]> | undefined> => {
  return await client<ApiResponse<IOrder[]>>(`${config.AUTH_BASE_URL}/api/order`, 'GET');
};


export const getOrderByOrderNumber = async (
  orderNumber: string
): Promise<ApiResponse<IOrder> | undefined> => {
  return await client<ApiResponse<IOrder>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}`,
    'GET'
  );
};

export const updateOrder = async (
  orderNumber: string,
  orderItems: IUpdateOrderItem
): Promise<ApiResponse<IOrder> | undefined> => {
  return await client<ApiResponse<IOrder>>(
    `${config.AUTH_BASE_URL}/api/order/item/update/${orderNumber}`,
    'PUT',
    { body: orderItems }
  );
};
export const saveAndUpdateOrder = async (
  orderNumber: string,
  orderItems: any
): Promise<ApiResponse<IOrder> | undefined> => {
  return await client<ApiResponse<IOrder>>(
    `${config.AUTH_BASE_URL}/api/order/save/${orderNumber}`,
    'PUT',
    { body: orderItems }
  );
 
};

export const getAllSubscription = async (params: any): Promise<any> => {
  const { page, limit, searchKey, startDate, endDate } = params;
  return await client<ApiResponse<PaginatedUserReponse | null>>(
    `${config.AUTH_BASE_URL}/api/order/subscription/all/${page}/${limit}?searchKey=${searchKey}&startDate=${startDate}&endDate=${endDate}`
  );
};
export const getOrdersInSubscription = async (
  subscriptionNumber: string
): Promise<ApiResponse<SubscriptionItem> | undefined> => {
  return await client<ApiResponse<SubscriptionItem>>(
    `${config.AUTH_BASE_URL}/api/order/subscription/${subscriptionNumber}`,
    'GET'
  );
};

export const getUserSubscriptions = async (): Promise<ApiResponse<ISubscription[]> | undefined> => {
  return await client<ApiResponse<ISubscription[]>>(
    `${config.AUTH_BASE_URL}/api/order/recurring/all`,
    'GET'
  );
};
export const costOrder = async (
  orderNumber: string,
  order: ICostOrder
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/cost/`,
    'PUT',
    { body: order }
  );

  return res;
};

export const updateDeliveryFees = async (
  orderNumber: string,
  order: ICostOrder
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/delivery-fee`,
    'PUT',
    { body: order }
  );
  toast.success(`${res?.message}`)
  return res;
};

export const readyToDispatchOrder = async (
  orderNumber: string
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/ready-to-dispatch`,
    'PUT'
  );

  return res;
};

export const dispatchOrder = async (
  orderNumber: string,
  dispatch: string
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/dispatch/`,
    'PUT',
    { body: { orderNumber, dispatch } }
  );

  return res;
};

export const deliverOrder = async (
  orderNumber: string
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/deliver/`,
    'PUT'
  );

  return res;
};
export const cancelOrder = async ({
  orderNumber,
  cancelReason
}: {
  orderNumber: string;
  cancelReason: string;
}): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/cancel`,
    'PUT',
    {
      body: { orderNumber, cancelReason }
    }
  );
};
export const cancelSubscription = async ({
  subscriptionNumber,
  cancelReason
}: {
  subscriptionNumber: string;
  cancelReason: string;
}): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/subscription/${subscriptionNumber}/cancel`,
    'PUT',
    {
      body: { subscriptionNumber, cancelReason }
    }
  );
};

export const delOrder = async (orderNumber: string): Promise<ApiResponse<null> | undefined> => {
  return await client<ApiResponse<null> | undefined>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}`,
    'DELETE'
  );
};

export const closeOrderWithReason = async (orderNumber: string, message: string) => {
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/close-order/${orderNumber}`,
    'POST',
    { body: { message } }
  );
};

export const adminCloseSubscription = async (subscriptionNumber: string, message: string) => {
  return await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/close-subscription/${subscriptionNumber}`,
    'POST',
    { body: { message } }
  );
};
export const pauseSubscription = async ({
  subscriptionNumber,
  pauseReason,
  resumptionDate
}: {
  subscriptionNumber: string;
  pauseReason: string;
  resumptionDate?: string;
}): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${subscriptionNumber}/pause`,
    'PUT',
    {
      body: { pauseReason, resumptionDate }
    }
  );
};

export const resumeSubscription = async ({
  subscriptionNumber
}: {
  subscriptionNumber: string;
}): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/order/${subscriptionNumber}/resume`,
    'PUT'
  );
};

export const makePayment = async (
  query: string,
  payload: {
    discountDuration?: number;
    discountPercentage?: number;
    saveCard?: boolean;
    amount?: number;
  }
) => {
  const res = await client<ApiResponse<{ paymentUrl: string }>>(
    `${config.AUTH_BASE_URL}/api/${query}`,
    'POST',
    { body: payload }
  );
  return res;
};

export const verifyPayment = async (
  query: string,
  body?: { userId: string | null; orderNumber?: string | null; vendor?: string }
): Promise<ApiResponse<string> | undefined> => {
  const res = await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/${query}`, 'POST', {
    body
  });
  return res;
};
export const verifyPaymentManually = async (
  orderNumber: string,
  orderRef: string,
  schedule?: boolean
) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/update-payment-status/${orderNumber}/${orderRef}`,
    'PUT',
    { body: { schedule } }
  );
  return res;
};
export const saveComment = async (
  orderNumber: string,
  body?: { message: string; service: string }
) => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/add-comment/${orderNumber}`,
    'POST',
    { body }
  );
  return res;
};

export const addCreditToOrder = async (orderNumber: string) => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/add-credit`,
    'PUT'
  );
  return res;
};
export const remitCreditOnOrder = async (orderNumbers: string[]) => {
  const payload = { items: orderNumbers };
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/remit-credit`,
    'PUT',
    { body: payload }
  );
  return res;
};
export const payWithWallet = async (orderNumber: string, payload: {
  discountDuration?: number;
  discountPercentage?: number;
  saveCard?: boolean;
  amount?: number;
}) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/wallet-payment`,
    'PUT',
    { body: payload }
  );
  return res;
};
export const getDashboardSummary = async (query?: string) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/dashboard/summary/${query}`,
    'GET'
  );
  return res;
};
export const getTopCategories = async (query?: string) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/dashboard/top-category/${query}`,
    'GET'
  );
  return res;
};

export const getTopProducts = async (query?: string) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/dashboard/top-products/${query}`,
    'GET'
  );
  return res;
};

export const subscribeNotification = async (body: { token: string }) => {
  const res = await client<ApiResponse<any>>(
    `${config.AUTH_BASE_URL}/api/order/subscribeToNotification`,
    'POST',
    { body }
  );
  return res;
};

export const assignRunnerToOrder = async (
  body: any,
  orderNumber: string
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/assign-user`,
    'POST',
    { body }
  );
  return res;
};

export const assignRunnerToSubscription = async (
  body: any,
  subscriptionNumber: string
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${subscriptionNumber}/subscription/assign-runner`,
    'PUT',
    { body }
  );
  return res;
};

export const moveOrderStage = async (body: {
  stage: string;
  orderNumber: string;
}): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/stage/move`,
    'PUT',
    { body }
  );
  return res;
};

export const editCustomerSource = async (
  orderNumber: string,
  source: string
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/customer-source`,
    'PUT',
    {
      body: {
        source
      }
    }
  );
  return res;
};

export const getTags = async (): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(`${config.AUTH_BASE_URL}/api/tag`, 'GET');
  return res;
};
export const createTags = async (body: {
  label: string;
  color?: string;
}): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(`${config.AUTH_BASE_URL}/api/tag`, 'POST', { body });
  return res;
};
export const assignTagsToOrder = async (
  orderNumber: string,
  body: { tag: string }
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/tag`,
    'PUT',
    { body }
  );
  return res;
};
export const assignTagsToSubscription = async (
  subscriptionNumber: string,
  body: { tag: string }
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${subscriptionNumber}/subscription-tag`,
    'PUT',
    { body }
  );
  return res;
};
export const updateProgress = async (
  orderNumber: string,
  body: { progress: string; id: string }
): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/item-progress`,
    'PUT',
    { body }
  );
  return res;
};

export const refundAmount = async (payload: {
  orderData: IOrder;
  refundDetails: IRefundReason;
  channel: string;
}): Promise<ApiResponse<null> | undefined> => {
  const { channel, orderData, refundDetails } = payload;

  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/finance/refunds/${channel}`,
    'POST',
    { body: { ...orderData, ...refundDetails } }
  );
  return res;
};

export const pickupOrder = async (orderNumber: string): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/pickup`,
    'PUT'
  );
  return res;
};

export const logOrder = async (orderNumber: string): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/log`,
    'PUT'
  );
  return res;
};
export const updateOrderStatePharmDispatch = async (orderNumber: string, type: string, body: {
  orderItemId: string;
  partnerId: string;
}): Promise<ApiResponse<null> | undefined> => {
  const res = await client<ApiResponse<null>>(
    `${config.AUTH_BASE_URL}/api/order/${orderNumber}/pharmacy-dispatch/${type}`,
    'PUT', {
    body
  }
  );
  return res;
};
