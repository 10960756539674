import {
  FormControl,
  Input,
  Flex,
  Select,
  Button,
  Card,
  Heading,
  Divider,
  VStack,
  FormErrorMessage
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { states } from 'data/location';
import { useStore } from 'store';
import { Form, Formik } from 'formik';
import { ReceiverDetailsSchema, LoggedInAddressSchema } from 'validations/authValidators';
import { toast } from 'react-hot-toast';
import GooglePlacesInput from 'components/placeinput';

const ReceipientFormDetails = () => {

  const {
    CheckoutStore: {
      isDeliveryAddressCardOpen,
      setDeliveryAddress,
      toggleGuestAddressForm,
      selectAddress,
      deliveryAddress,
      addressToEditIndex,
      setAddressToEditIndex,
      newOrderAddressForm,
      toggleDeliveryAddressCard
    },
    UserStore: {
      updateUserAddressAdmin,
      addFamilyAndFriendsAdmin,
      userFamilyAddress,
      newFamAndFriend,
      updateFamilyAndFriendsAdmin,
      isFamilyandFriendsState: { isMember },
      selectedUser,
    }
  } = useStore();

  const handleDiscardChanges = () => {
    setAddressToEditIndex('');
    selectAddress('default', false);
    if (!isMember) toggleGuestAddressForm();
  };
  let initialValues = deliveryAddress;
  if (
    newOrderAddressForm ||
    (addressToEditIndex === 'default' && deliveryAddress.email === '') ||
    addressToEditIndex === 'guest'
  ) {
    initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      state: '',
      phoneNumber: '',
      street: '',
      longitude: '',
      latitude: '',
      landmark: ''
    };
  }
  const isAddressEmpty = Object.entries(deliveryAddress).some(([key, value]) => {
    return (
      key !== 'latitude' &&
      key !== 'longitude' &&
      (value === null || value === '' || value === undefined)
    );
  });

  const handleSubmit = async (values: IAddress) => {
    if (
      JSON.stringify(selectedUser) === '{}' ||
      selectedUser?.status === false ||
      (selectedUser?.status === true && isAddressEmpty) ||
      isAddressEmpty
    ) {
      const payload = {
        street: values.street,
        city: values.city,
        state: values.state,
        landmark: values.landmark,
        firstName: values.firstName || selectedUser?.personalInformation?.firstName,
        lastName: values.lastName || selectedUser?.personalInformation?.lastName,
        longitude: values.longitude,
        latitude: values.latitude,
        email: values.email || selectedUser?.email,
        phoneNumber: values.phoneNumber || selectedUser?.personalInformation?.phoneNumber
      };
      setDeliveryAddress(payload);
      selectAddress('guest', false);
      toggleGuestAddressForm();
      toggleDeliveryAddressCard();
      toast.success('Personal details added successfully', {
        position: 'top-right'
      });

      return;
    } else {
      if (addressToEditIndex === 'default') {
        const payload = {
          street: values.street,
          city: values.city,
          state: values.state,
          landmark: values.landmark,
          longitude: values.longitude,
          latitude: values.latitude
        };

        await updateUserAddressAdmin(payload, 'user', selectedUser?.userId as string);
        selectAddress('default', false);
        setAddressToEditIndex('');

        return;
      } else {
        const familyAndFriendsAddress = {
          street: values.street,
          city: values.city,
          state: values.state,
          longitude: values.longitude,
          latitude: values.latitude,
          landmark: values.landmark,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber
        };

        if (newFamAndFriend === 'new') {
          addFamilyAndFriendsAdmin(
            { isMember, data: familyAndFriendsAddress },
            selectedUser?.userId as string
          );
          toast.success('Address details added successfully', {
            position: 'top-right'
          });
          selectAddress(`${userFamilyAddress.length - 1}`, true);
        } else {
          const familyandFriendsIndex = parseInt(addressToEditIndex);
          const fnfId = userFamilyAddress[familyandFriendsIndex]?._id;

          updateFamilyAndFriendsAdmin(
            { isMember: false, data: familyAndFriendsAddress },
            fnfId as string,
            selectedUser?.userId as string
          );
          toast.success('Address details updated successfully', {
            position: 'top-right'
          });

          selectAddress(`${addressToEditIndex}`, true);
        }
      }
    }
  };

  return (
    <>
      {!isDeliveryAddressCardOpen && (
        <Card p="2rem">
          <Heading fontSize="15px" mb="1rem">
            RECEIPIENT DETAILS
          </Heading>
          <Divider color="gray" mb="3" />
          <Formik
            initialValues={initialValues}
            validationSchema={
              JSON.stringify(selectedUser) === '{}' ? ReceiverDetailsSchema : LoggedInAddressSchema
            }
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem'
                }}
              >
                {addressToEditIndex !== 'default' && (
                  <>
                    <FormControl isInvalid={errors.firstName && touched.firstName ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="first name">
                        First name
                      </FormControl>
                      <Input
                        id="firstName"
                        value={values.firstName || selectedUser?.personalInformation?.firstName || ''}
                        name="firstName"
                        onChange={handleChange}
                        type="text"
                        fontSize={{ base: '1rem', md: '0.875rem' }}
                        placeholder="Enter first name"
                        _placeholder={{
                          fontSize: { base: '12px', md: '14px' }
                        }}
                        aria-label="first name"
                      />
                      <FormErrorMessage>
                        {errors.firstName && touched.firstName ? errors.firstName : null}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.lastName && touched.lastName ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="lastName">
                        Last name
                      </FormControl>
                      <Input
                        id="lastName"
                        name="lastName"
                        value={values.lastName || selectedUser?.personalInformation?.lastName || ''}
                        onChange={handleChange}
                        type="text"
                        fontSize={{ base: '1rem', md: '0.875rem' }}
                        placeholder="Enter last name"
                        _placeholder={{
                          fontSize: { base: '12px', md: '14px' }
                        }}
                        aria-label="last name"
                      />
                      <FormErrorMessage>
                        {errors.lastName && touched.lastName ? errors.lastName : null}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.email && touched.email ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="email">
                        Email Address
                      </FormControl>
                      <Input
                        id="email"
                        name="email"
                        value={values.email || selectedUser?.email || ''}
                        onChange={handleChange}
                        type="email"
                        placeholder="Email"
                        _placeholder={{
                          fontSize: { base: '12px', md: '14px' }
                        }}
                        fontSize={{ base: '1rem', md: '0.875rem' }}
                        aria-label="email"
                      />
                      <FormErrorMessage>
                        {errors.email && touched.email ? errors.email : null}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors.phoneNumber && touched.phoneNumber ? true : false}
                    >
                      <FormControl fontSize="14px" fontWeight="500" aria-label="phone number">
                        Phone number
                      </FormControl>
                      <Input
                        id="phoneNumber"
                        name="phoneNumber"
                        value={values.phoneNumber || selectedUser?.personalInformation?.phoneNumber || ''}
                        onChange={handleChange}
                        type="tel"
                        fontSize={{ base: '1rem', md: '0.875rem' }}
                        placeholder="Phone number"
                        _placeholder={{
                          fontSize: { base: '12px', md: '14px' }
                        }}
                        aria-label="phone number"
                      />
                      <FormErrorMessage>
                        {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}

                <GooglePlacesInput
                  defaultValue={values.street}
                  error={(errors.street && touched.street) || false}
                  handleChange={handleChange}
                  errorMsg={errors.street}
                  inputProps={{
                    _placeholder: {
                      fontSize: { base: '12px', md: '14px' }
                    }
                  }}
                />

                <Flex gap="2rem">
                  <VStack align="start" w="100%">
                    <FormControl isInvalid={errors.state && touched.state ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="state">
                        State
                      </FormControl>
                      <Select
                        id="state"
                        placeholder={'All locations'}
                        name="state"
                        value={values.state}
                        fontSize={{ base: '12px', md: '14px' }}
                        onChange={handleChange}
                        aria-label="state"
                      >
                        {states.map((state: { name: string }) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.state && touched.state ? errors.state : null}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                  <VStack align="start" w="100%">
                    <FormControl isInvalid={errors.city && touched.city ? true : false}>
                      <FormControl fontSize="14px" fontWeight="500" aria-label="city">
                        City
                      </FormControl>
                      {values.state !== 'Lagos' ? (
                        <Input
                          id="city"
                          name="city"
                          value={values.city}
                          disabled={!values.state}
                          onChange={handleChange}
                          type="text"
                          fontSize={{ base: '1rem', md: '0.875rem' }}
                          placeholder="Enter city"
                          _placeholder={{
                            fontSize: { base: '12px', md: '14px' }
                          }}
                          aria-label="city"
                        />
                      ) : (
                        <Select
                          id="city"
                          placeholder={'Select city'}
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          fontSize={{ base: '12px', md: '14px' }}
                          aria-label="city"
                        >
                          {states
                            .find((state: { name: string }) => state.name === values.state)
                            .cities.map(
                              (
                                city: {
                                  name: string;
                                },
                                index: number
                              ) => (
                                <option key={index} value={city.name}>
                                  {city.name}
                                </option>
                              )
                            )}
                        </Select>
                      )}
                      <FormErrorMessage>
                        {errors.city && touched.city ? errors.city : null}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Flex>

                <FormControl isInvalid={errors.landmark && touched.landmark ? true : false}>
                  <FormControl fontSize="14px" fontWeight="500" aria-label="landmark">
                    Landmark
                  </FormControl>
                  <Input
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onChange={handleChange}
                    type="text"
                    fontSize={{ base: '1rem', md: '0.875rem' }}
                    placeholder="Enter Landmark"
                    _placeholder={{
                      fontSize: { base: '12px', md: '14px' }
                    }}
                    aria-label="landmark"
                  />
                  <FormErrorMessage>
                    {errors.landmark && touched.landmark ? errors.landmark : null}
                  </FormErrorMessage>
                </FormControl>

                <Flex justify="end" gap="1rem">
                  <Button
                    aria-label="discard changes"
                    variant="outline"
                    color="primary.main"
                    borderColor="primary.main"
                    onClick={handleDiscardChanges}
                    type="button"
                    size="sm"
                    display={
                      deliveryAddress === null ||
                      values.firstName === '' ||
                      values.lastName === '' ||
                      values.email === '' ||
                      values.phoneNumber === '' ||
                      values.street === '' ||
                      values.state === '' ||
                      values.city === '' ||
                      values.landmark === ''
                        ? 'none'
                        : 'block'
                    }
                  >
                    Discard Changes
                  </Button>
                  <Button type="submit" size="sm" aria-label="save changes">
                    {' '}
                    Save Changes
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default observer(ReceipientFormDetails);
